const aliases = {
  home: {
    page: true,
    name: 'app.root.l1.l2.home'
  },
  deposit: {
    page: false,
    private: true,
    name: 'cashbox'
  },
  login: {
    page: false,
    name: 'login'
  },
  registration: {
    page: false,
    name: 'registration'
  },
  fastRegistration: {
    page: true,
    name: 'app.root.paynplay'
  },
  paynplay: {
    page: true,
    name: 'app.root.paynplay'
  },
  promotions: {
    page: true,
    name: 'app.root.l1.l2.promotions.all'
  },
  promo: {
    page: true,
    name: 'app.root.l1.l2.promotions.all'
  },
  realGame: {
    page: true,
    name: 'app.root.real'
  },
  tournaments: {
    page: true,
    name: 'app.root.l1.l2.tournaments'
  },
  lamborghini: {
    page: true,
    name: 'app.root.l1.l2.lamborghini',
  },
  profile: {
    page: true,
    name: 'app.root.l1.profile.dashboard'
  },
  successBonusActivation: {
    page: true,
    name: 'app.root.l1.profile.bonuses'
  },
  gameHall: {
    page: true,
    name: 'app.root.l1.casino.gamehall'
  },
  bet: {
    page: true,
    name: 'app.root.l1.l2.sport.live'
  },
  sport: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive'
  },
  live: {
    page: true,
    name: 'app.root.l1.casino.live-casino'
  },
  category: {
    page: true,
    name: 'app.root.l1.casino.category'
  },
  games: {
    page: true,
    name: 'app.root.l1.casino.gamehall'
  },
  sportEvent: {
    page: true,
    name: "app.root.l1.l2.sport.prelive"
  },
  missedData: {
    page: true,
    name: 'app.root.missedData'
  },
  terms: {
    page: true,
    name: 'app.root.l1.l2.l3.rules',
  },
  bonus_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: {
      name: 'cash-crab'
    }
  },
  cash_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: {
      name: 'cash_crab'
    }
  },
  claw: {
    page: true,
    name: 'app.root.claw_games.gamepage'
  },
  bonuses: {
    page: true,
    name: 'app.root.l1.profile.bonuses',
  },
  evolution: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'evolution'
    }
  },
  pragmatic: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'pragmatic'
    }
  },
  playngo: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'playngo'
    }
  },
  halloween: {
    page: true,
    name: "app.root.l1.tournament.page",
    params: {
      name: 'halloween'
    }
  },

  // WorldCup
  worldcupTournament: {
    page: true,
    name: 'app.root.l1.l2.worldcup'
  },
  worldCupSport: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive'
  },
  worldCupPragmatic: {
    page: true,
    name: 'app.root.l1.casino.provider',
    params: {
      name: 'pragmatic'
    }
  },
  worldCupPlayngo: {
    page: true,
    name: 'app.root.l1.casino.provider',
    params: {
      name: 'playngo'
    }
  },
  worldCupElagames: {
    page: true,
    name: 'app.root.l1.casino.provider',
    params: {
      name: 'ela'
    }
  },
  worldCupEvolution: {
    page: true,
    name: 'app.root.l1.casino.provider',
    params: {
      name: 'evolution'
    }
  },
  worldCupBetNow: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive',
    params: {
      champids: '3146'
    }
  },
  threeoaks: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'threeoaks'
    }
  },
  ela: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'ela'
    }
  },
  spinomenal: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'spinomenal'
    }
  },
  christmasTournament: {
    page: true,
    name: "app.root.l1.l2.tournament",
    params: {
      name: 'party-raffle'
    }
  },
  relax: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'relax'
    }
  },
  skywind: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'skywind'
    }
  },
  netent: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'netent'
    }
  },
  pushgaming: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'pushgaming'
    }
  },
  playtech: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'playtech'
    }
  },
  quickspin: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'quickspin'
    }
  },
  redtiger: {
    page: true,
    name: "app.root.l1.casino.provider",
    params: {
      name: 'redtiger'
    }
  },
  promoTennis: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive',
    params: {
      champids: 3158,
    },
  },
  tennisTournament: {
    page: true,
    private: true,
    name: 'app.root.l1.l2.sport.prelive',
    params: {
      champids: 3013
    }
  },
  summer: {
    page: true,
    name: 'app.root.seasons',
  },
  summerGame: {
    page: true,
    name: 'app.root.summer-game',
  },
  soccer: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive',
    params:{
      sportids: '66'
    },
  },
  tennis: {
    page: true,
    name: 'app.root.l1.l2.sport.prelive',
    params:{
      sportids: '68'
    },
  },
  euroFootball: {
    page: true,
    name: "app.root.l1.l2.sport.prelive",
    params: {
      champids: "3031"
    }
  },
};

export { aliases };
