(function () {

    'use strict';

    const directive = { name: 'toTopOnStateChange' };

    controller.$inject = [ '$state' ];

    function controller( $state ){

        function link( scope, element, attrs ){

            scope.$on( '$stateChangeSuccess', () => {
                element[0].scrollTop = 0;
            } );

        }


        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
