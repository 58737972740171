//- All property styles you can check on this page:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/docs/widgets-tokens--page
//- On this page you can try constructor styles:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/story/widgets-wtabbedeventsbytype--live-now

const $_ALTENARCONFIG = {};

let widget = {
  OddBox: {
    variant: 0,
    spacing: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.1)",
    background: "red",
    paddingVertical: 10,
    paddingHorizontal: 12,
    oddFont: ["Roboto Condensed", 16, "normal", 400, "normal"],
    labelFont: ["Roboto Condensed", 16, "normal", 400, "normal"],
    oddColor: "#ffffff",
    labelColor: "rgba(255, 255, 255, 0.6)",
    arrowUp: [24, 24, "#4caf50"],
    arrowDown: [24, 24, "#f44336"]
  },
  OddBoxSelected: {
    borderColor: "#fff",
    background: "#05207B",
    oddColor: "#FFFFFF",
    labelColor: "#FFFFFF"
  },
  MarketBox: {
    width: "100%",
    spacing: 4
  },
  EventBox: {
    borderWidth: 1,
    borderColor: "#E9EBF7",
    borderStyle: "solid",
    borderRadius: 0,
    background: "#FFFFFF",
    paddingVertical: 12,
    paddingHorizontal: 12,
    liveTimeFont: ["Roboto Condensed", 14, "normal", 500, "21px"],
    dateTimeFont: ["Roboto Condensed", 12, "normal", 600, "20px"],
    categoryChampionshipFont: ["Roboto Condensed", 12, "normal", 600, "20px"],
    competitorFont: ["Roboto Condensed", 14, "normal", 700, "21px"],
    scoreFont: ["Roboto Condensed", 14, "normal", 700, "21px"],
    liveTimeColor: "#65697B",
    dateTimeColor: "rgba(0, 0, 0, 0.87)",
    categoryChampionshipColor: "rgba(0, 0, 0, 0.54)",
    competitorColor: "rgba(0, 0, 0, 0.87)",
    scoreColor: "rgba(0, 0, 0, 0.87)"
  },
  SportTab: {
    borderWidth: 0,
    borderColor: "unset",
    borderStyle: "none",
    font: ["proxima-nova,sans-serif", 16, "normal", 700, "14px"],
    background: "#fff",
    color: "#05207B",
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 0,
    spacing: 16,
    textTransform: "uppercase"
  },
  SportTabSelected: {
    font: ["Roboto Condensed", 16, "normal", 700, "14px"],
    indicatorColor: "#fff",
    borderColor: "#fff",
    color: "#fff",
    background: "#05207B",
    textTransform: "uppercase"
  },
  TabbedTopSportEvents: {
    borderWidth: 1,
    borderColor: "#E9EBF7",
    borderStyle: "solid",
    borderRadius: 0,
    paddingHorizontal: 16,
    paddingVertical: 16,
    background: "#fff",
    titleColor: "#05207B",
    titleFont: ["Roboto Condensed", 18, "italic", 900, "40px"],
    titleTextTransform: "uppercase"
  },
  EventList: {
    variant: 1,
    rowSpacing: 8,
    columnSpacing: 16
  },
  LiveIndicator: {
    variant: 0,
    borderWidth: 0,
    borderColor: "unset",
    borderStyle: "none",
    font: ["Roboto Condensed", 12, "normal", 700, "21px"],
    textTransform: "uppercase",
    background: "#CC0034",
    color: "#fff",
    borderRadius: 0,
    paddingHorizontal: 3,
    paddingVertical: 1,
    clipPath: "unset"
  }
};

let carousel = {
  OddBox: {
    variant: 3,
    spacing: 4,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "rgb(134, 138, 146)",
    background: "rgb(246, 246, 244)",
    paddingVertical: 10,
    paddingHorizontal: 12,
    oddFont: ["Roboto Condensed", 12, "normal", 400, "normal"],
    labelFont: ["Roboto Condensed", 12, "normal", 400, "normal"],
    oddColor: "rgb(134, 138, 146)",
    labelColor: "rgb(134, 138, 146)",
    arrowUp: [24, 24, "#4caf50"],
    arrowDown: [24, 24, "#f44336"]
  },
  OddBoxSelected: {
    borderColor: "rgb(21, 193, 135)",
    background: "#fff",
    oddColor: "rgb(21, 193, 135)",
    labelColor: "rgb(21, 193, 135)"
  },
  BannerEventBox: {
    variant: 0,
    width: 260,
    borderWidth: 0,
    borderColor: "transparent",
    borderRadius: 16,
    background: "#fff",
    paddingVertical: 12,
    paddingHorizontal: 12,
    timeFont: ["Roboto Condensed", 24, "normal", 700, "28px"],
    dateFont: ["Roboto Condensed", 14, "normal", 400, "14px"],
    championshipFont: ["Roboto Condensed", 16, "normal", 400, "20px"],
    competitorFont: ["Roboto Condensed", 16, "normal", 700, "24px"],
    messageBodyFont: ["Roboto Condensed", 16, "normal", 500, "24px"],
    messageHeaderFont: ["Roboto Condensed", 16, "normal", 500, "24px"],
    timeColor: "#19191D",
    dateColor: "#868A92",
    championshipColor: "#868A92",
    competitorColor: "#19191D",
    messageHeaderColor: "#19191D",
    messageBodyColor: "#19191D",
    dividerColor: "transparent",
    dividerWidth: 1,
    dividerHeight: "85%"
  },
  MarketBox: {
    font: ["Roboto Condensed", 14, "normal", 700, "16px"],
    color: "#ffffff",
    spacing: 4
  },
  CompetitorLogo: {
    height: 60,
    width: 60,
    background: "rgb(246, 246, 244)",
    borderRadius: 50,
    borderColor: "#868A92",
    borderWidth: "1",
    offset: -10,
    placeholderColor: "#F6F6F4"
  },
  EventsCarousel: {
    borderWidth: 0,
    borderColor: "unset",
    borderRadius: 0,
    width: "unset",
    paddingHorizontal: 0,
    paddingVertical: 0,
    spacing: 16
  },
  EventsCarouselControls: {
    borderWidth: 0,
    borderColor: "#2A394E",
    borderRadius: 50,
    dotBackground: "rgba(255, 255, 255, 0.87)",
    selectedDotBackground: "#273242",
    background: "rgba(45, 45, 50, 0.8)",
    color: "#FFDC26",
    height: 36,
    width: 36
  },
  EventsCarouselControlsHovered: {
    background: "rgba(25, 25, 29, 0.8)",
    color: "#FFDC26"
  },
  LiveIndicator: {
    variant: 0,
    borderWidth: 0,
    borderColor: "unset",
    borderStyle: "none",
    font: ["Roboto Condensed", 12, "normal", 700, "21px"],
    textTransform: "uppercase",
    background: "#CC0034",
    color: "#fff",
    borderRadius: 0,
    paddingHorizontal: 3,
    paddingVertical: 1,
    clipPath: "unset"
  }
};

$_ALTENARCONFIG.widget = {
  tokens: {
    liveNow: widget,
    highlights: widget
  },
  carousel
};

export { $_ALTENARCONFIG };
